import React, { FunctionComponent, useState } from 'react';
import { EditInfoFormProps, styles } from './PersonalInfoForm';
import Box from '@mui/material/Box';
import { DesktopDialogTitle } from '../../../DialogPaperContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {
    BUSINESS_DAYS,
    INCOME_TYPE,
    MONTH_DAYS,
    PAY_FREQUENCIES,
    STATES,
} from '../../../../config/membersArea';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { Theme, useTheme } from '@mui/material/styles';
import { KnownBreakpoints } from '../../../../common/constants';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    formatNullableScalar,
    fromFormatMoney,
    toPayFrequency,
} from '../../../../common/helpers/format';
import getBiWeeklyDates from '../../../../common/helpers/getBiWeeklyDates';
import { format } from 'date-fns';
import { ApiClient, isOffline } from '../../../../common/helpers';
import { getKeyByValue } from '../../../../common/helpers/getKeyByValue';
import useAuthorized from '../../../../common/hooks/useAuthorized';
import { Loader } from '../../PageLoader';
import { Alert } from '@mui/material';
import WarningIcon from '../../../../assets/SvgIcons/WarningIcon';
import MaskedTextField from '../../../MaskedTextField';
import asyncLogger from '../../../../common/logger';
import OfflineError from '../../../OfflineError';
import { useCustomer } from '../../../../common/hooks/AccountInfoProvider';
import { Customer } from '../../../../common/types/Customer';
import {
    AddressType,
    PayrollType,
    PhoneType,
} from '../../../../common/types/enums';

export interface EmploymentInfoState {
    employer: string;
    workAddress: string;
    city: string;
    state: string;
    zip: string;
    workPhone: string;
    monthlyIncome: string;
    paymentType: string;
    payFrequency: string;
    biWeeklyDayOfTheWeek: string | undefined;
    biWeeklyLastPayDay: string | undefined;
    weeklyDayOfTheWeek: string | undefined;
    monthlyDayOfTheMonth: string | undefined;
    semiMonthlyFirstDay: string | undefined;
    semiMonthlySecondDay: string | undefined;
}

const EmploymentInfoForm: FunctionComponent<EditInfoFormProps> = props => {
    const authUser = useAuthorized();
    const {
        content,
        onClose,
        onSuccess,
        onError,
    } = props;
    const theme = useTheme<Theme>();
    const isDesktop = useMediaQuery(
        theme.breakpoints.up(KnownBreakpoints.tablet),
    );
    const customer = useCustomer();
    const [state, setState] = useState<EmploymentInfoState>({
        employer: formatNullableScalar(content['Work place'] as string),
        workAddress: formatNullableScalar(content['Work address'] as string),
        city: formatNullableScalar(content['City'] as string),
        state: formatNullableScalar(content['State'] as string),
        zip: formatNullableScalar(content['Zip code'] as string),
        workPhone: formatNullableScalar(content['Work phone'] as string),
        monthlyIncome: fromFormatMoney(content['Monthly income'] as string),
        paymentType: formatNullableScalar(content['Payment type'] as string),
        payFrequency: formatNullableScalar(content['Pay frequency'] as string),
        biWeeklyDayOfTheWeek: content['Pay frequency'] === 'Bi-Weekly'
            ? formatNullableScalar(content['Day of the week'] as string)
            : undefined
        ,
        biWeeklyLastPayDay: formatNullableScalar(
            content['Last pay date'] as string,
        ),
        weeklyDayOfTheWeek: content['Pay frequency'] === 'Weekly'
            ? formatNullableScalar(content['Day of the week'] as string)
            : undefined
        ,
        monthlyDayOfTheMonth: formatNullableScalar(
            content['Day of the month'] as string,
        ),
        semiMonthlyFirstDay: formatNullableScalar(
            content['1st pay day'] as string,
        ),
        semiMonthlySecondDay: formatNullableScalar(
            content['2nd pay day'] as string,
        ),
    });
    const [loading, setLoading] = useState(false);
    const [
        zipError,
        setZipError,
    ] = useState<string | undefined>(undefined);
    const [
        phoneError,
        setPhoneError,
    ] = useState<string | undefined>(undefined);
    const [
        incomeError,
        setIncomeError,
    ] = useState<string | undefined>(undefined);
    const [
        emptyFieldError,
        setEmptyFieldError,
    ] = useState<string | undefined>(undefined);
    const [
        submitError,
        setSubmitError,
    ] = useState<string | undefined>(undefined);
    const [networkError, setNetworkError] = useState(false);
    const handleChange = (prop: string) => (
        event: React.ChangeEvent<
            HTMLElement & { value: any }
            > | SelectChangeEvent,
    ) => {
        setEmptyFieldError(undefined);
        setZipError(undefined);
        setPhoneError(undefined);
        setIncomeError(undefined);
        setSubmitError(undefined);

        const value = event.target.value;
        const isChanged = value !== (state as any)[prop];

        if (prop === 'payFrequency') {
            setState({
                ...state,
                [prop]: value,
                biWeeklyDayOfTheWeek: undefined,
                biWeeklyLastPayDay: undefined,
                weeklyDayOfTheWeek: undefined,
                monthlyDayOfTheMonth: undefined,
                semiMonthlyFirstDay: undefined,
                semiMonthlySecondDay: undefined,
            });

            return ;
        }

        if (prop === 'biWeeklyDayOfTheWeek' && isChanged) {
            const dates = getBiWeeklyDates(value);

            setState({
                ...state,
                [prop]: value,
                biWeeklyLastPayDay: format(dates[0], 'MM/dd/yyyy'),
            });

            return ;
        }

        setState({ ...state, [prop]: event.target.value });
    };

    function defineFrequencyOptions(frequency: string) {
        if (frequency === 'Weekly') {
            return <Grid item mobile={ 12 } tablet={ 6 }>
                <FormControl fullWidth>
                    <InputLabel>
                        Day of week*
                    </InputLabel>
                    <Select
                        value={ state.weeklyDayOfTheWeek || '' }
                        label="Day of week*"
                        onChange={ handleChange('weeklyDayOfTheWeek') }
                        error={ !!emptyFieldError && !state.weeklyDayOfTheWeek }
                        sx={ styles.select }
                    >
                        { BUSINESS_DAYS.map((
                            day: string,
                            index: number,
                        ) => (
                            <MenuItem
                                value={ day }
                                key={ index }
                            >
                                { day }
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>;
        }

        if (frequency === 'Monthly') {
            return <Grid item mobile={ 12 } tablet={ 6 }>
                <FormControl fullWidth>
                    <InputLabel>
                        Day of month*
                    </InputLabel>
                    <Select
                        value={ state.monthlyDayOfTheMonth || '' }
                        label="Day of month*"
                        onChange={ handleChange('monthlyDayOfTheMonth') }
                        error={ !!emptyFieldError
                            && !state.monthlyDayOfTheMonth
                        }
                        sx={ styles.select }
                    >
                        { MONTH_DAYS.map((
                            day: string,
                            index: number,
                        ) => (
                            <MenuItem
                                value={ day }
                                key={ index }
                            >
                                { day }
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>;
        }

        if (frequency === 'Bi-Weekly') {
            return <React.Fragment>
                <Grid item mobile={ 12 } tablet={ 6 }>
                    <FormControl fullWidth>
                        <InputLabel>
                            Day of week*
                        </InputLabel>
                        <Select
                            value={ state.biWeeklyDayOfTheWeek || '' }
                            label="Day of week*"
                            onChange={ handleChange('biWeeklyDayOfTheWeek') }
                            error={ !!emptyFieldError
                                && !state.biWeeklyDayOfTheWeek
                            }
                            sx={ styles.select }
                        >
                            { BUSINESS_DAYS.map((
                                day: string,
                                index: number,
                            ) => (
                                <MenuItem
                                    value={ day }
                                    key={ index }
                                >
                                    { day }
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                { state.biWeeklyDayOfTheWeek && <Grid
                    item
                    mobile={ 12 }
                    tablet={ 6 }
                >
                    <FormControl fullWidth>
                        <InputLabel>
                            Last pay date*
                        </InputLabel>
                        <Select
                            value={ state.biWeeklyLastPayDay || '' }
                            label="Last pay date*"
                            onChange={ handleChange('biWeeklyLastPayDay') }
                            error={ !!emptyFieldError
                                && !state.biWeeklyLastPayDay
                            }
                            sx={ styles.select }
                        >
                            { getBiWeeklyDates(state.biWeeklyDayOfTheWeek).map((
                                day: Date,
                                index: number,
                            ) => (
                                <MenuItem
                                    value={ format(day, 'MM/dd/yyyy') }
                                    key={ index }
                                >
                                    { format(day, 'MM/dd/yyyy') }
                                </MenuItem>
                            )) }
                        </Select>
                    </FormControl>
                </Grid> }
            </React.Fragment>;
        }

        if (frequency === 'Semi-Monthly') {
            return <React.Fragment>
                <Grid item mobile={ 12 } tablet={ 6 }>
                    <FormControl fullWidth>
                        <InputLabel>
                            1st day of month*
                        </InputLabel>
                        <Select
                            value={ state.semiMonthlyFirstDay || '' }
                            label="1st day of month*"
                            onChange={ handleChange('semiMonthlyFirstDay') }
                            error={ !!emptyFieldError
                                && !state.semiMonthlyFirstDay
                            }
                            sx={ styles.select }
                        >
                            { MONTH_DAYS.filter(key => key !== 'EOM').map((
                                day: string,
                                index: number,
                            ) => (
                                <MenuItem
                                    value={ day }
                                    key={ index }
                                >
                                    { day }
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                { state.semiMonthlyFirstDay && <Grid
                    item
                    mobile={ 12 }
                    tablet={ 6 }
                >
                    <FormControl fullWidth>
                        <InputLabel>
                            2nd day of month*
                        </InputLabel>
                        <Select
                            value={ state.semiMonthlySecondDay || '' }
                            label="2nd day of month*"
                            onChange={ handleChange('semiMonthlySecondDay') }
                            error={ !!emptyFieldError
                                && !state.semiMonthlySecondDay
                            }
                            sx={ styles.select }
                        >
                            { MONTH_DAYS.slice(MONTH_DAYS.indexOf(
                                state.semiMonthlyFirstDay,
                            ) + 1).map((
                                day: string,
                                index: number,
                            ) => (
                                <MenuItem
                                    value={ day }
                                    key={ index }
                                >
                                    { day }
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid> }
            </React.Fragment>;
        }
    }

    const handleSaveChanges = async(): Promise<void> => {
        if (!authUser) {
            return ;
        }

        if (state.employer === ''
            || state.workAddress === ''
            || state.city === ''
            || state.zip === ''
            || state.workPhone === ''
            || state.monthlyIncome === ''
        ) {
            setEmptyFieldError('Required');

            return ;
        }

        if (state.zip.includes('_')) {
            setZipError('Invalid Zip Code!');

            return;
        }

        if (state.workPhone.includes('_')) {
            setPhoneError(() => 'Invalid Work phone number!');

            return;
        }

        if (state.monthlyIncome === '') {
            setIncomeError(() => 'Invalid income!');

            return;
        }

        if (state.payFrequency === 'Weekly' && !state.weeklyDayOfTheWeek) {
            setEmptyFieldError('Required');

            return ;
        }

        if (state.payFrequency === 'Bi-Weekly' && (!state.biWeeklyDayOfTheWeek
            || !state.biWeeklyLastPayDay
        )) {
            setEmptyFieldError('Required');

            return ;
        }

        if (state.payFrequency === 'Monthly' && !state.monthlyDayOfTheMonth) {
            setEmptyFieldError('Required');

            return ;
        }

        if (state.payFrequency === 'Semi-Monthly'
            && (!state.semiMonthlyFirstDay || !state.semiMonthlySecondDay)
        ) {
            setEmptyFieldError('Required');

            return ;
        }

        setLoading(true);

        try {
            const { data, errors } = await ApiClient.request<{
                data: { updateCustomer: Customer };
                errors: { message: string }[];
            }>('/updateCustomer', {
                body: {
                    input: {
                        id: String(customer?.id),
                        employments: [{
                            name: state.employer,
                            address: {
                                type: AddressType.Common,
                                street: state.workAddress,
                                city: state.city,
                                state: getKeyByValue(STATES, state.state),
                                zip: state.zip,
                            },
                            phones: [{
                                phoneNumber: state.workPhone,
                                type: PhoneType.Common,
                            }],
                            monthlyIncome: Number(state.monthlyIncome),
                            incomeType: state.paymentType,
                            primary: true,
                            payrollType: PayrollType.Direct,
                            ...toPayFrequency(state),
                        }],
                    } as Partial<
                        Omit<Customer, 'employments' | 'bankAccounts'> & {
                            bankAccounts?: Partial<
                                Customer['bankAccounts'][0]
                            >[];
                            employments?: Partial<
                                Customer['employments'][0]
                            >[];
                        }>,
                    withAuth: true,
                },
            });

            const [error] = errors || [];

            if (error) {
                asyncLogger.error(error?.message);
                setSubmitError(
                    'Updating Attempt Error. Please make sure that all data is correct!',
                );
            }

            if (!error && data?.updateCustomer?.id) {
                onSuccess?.(data);
                onClose?.();
            }
        } catch (err) {
            const isNetError = isOffline(err);
            setNetworkError(isNetError);

            if (!isNetError) {
                setSubmitError(
                    'Updating Attempt Error. Please make sure that '
                    + 'all data is correct!',
                );

                asyncLogger.error(err);

                onError?.(err);
            }
        }

        setLoading(false);

        return ;
    };

    if (loading) {
        return <Loader />;
    }

    return <Box sx={ styles.layout }>
        { networkError &&
            <OfflineError onClose={ () => setNetworkError(false) } />
        }
        <Box>
            { isDesktop && <DesktopDialogTitle
                title={ 'Edit Employment Information' }
            /> }
            <Box marginTop={ isDesktop ? '35px' : '45px' }>
                { (emptyFieldError
                    || zipError
                    || phoneError
                    || incomeError
                    || submitError) && <Alert
                        severity={ 'error' }
                        iconMapping={ {
                            error: <WarningIcon/>,
                        } }
                    >
                        { emptyFieldError
                            ? emptyFieldError
                            : zipError
                                ? zipError
                                : phoneError
                                    ? phoneError
                                    : incomeError
                                        ? incomeError
                                        : submitError
                        }
                    </Alert>
                }
                <Grid container spacing={ 1 }>
                    <Grid item mobile={ 12 } tablet={ 12 }>
                        <TextField
                            label="Employer*"
                            value={ state.employer }
                            sx={{ width: '100%' }}
                            onChange={ handleChange('employer') }
                            inputProps={{ maxLength: 255 }}
                            error={ state.employer === '' && !!emptyFieldError }
                        />
                    </Grid>
                    <Grid item mobile={ 12 } tablet={ 12 }>
                        <TextField
                            label="Work address*"
                            value={ state.workAddress }
                            sx={{ width: '100%' }}
                            onChange={ handleChange('workAddress') }
                            inputProps={{ maxLength: 255 }}
                            error={
                                state.workAddress === '' && !!emptyFieldError
                            }
                        />
                    </Grid>
                    <Grid item mobile={ 12 } tablet={ 4 }>
                        <TextField
                            label="City*"
                            value={ state.city }
                            sx={{ width: '100%' }}
                            onChange={ handleChange('city') }
                            inputProps={{ maxLength: 255 }}
                            error={ state.city === '' && !!emptyFieldError }
                        />
                    </Grid>
                    <Grid item mobile={ 12 } tablet={ 4 }>
                        <FormControl fullWidth>
                            <InputLabel>
                                State*
                            </InputLabel>
                            <Select
                                value={ state.state }
                                label="State*"
                                onChange={ handleChange('state') }
                                sx={ styles.select }
                            >
                                { Object.keys(STATES).map((
                                    state: string,
                                    index: number,
                                ) => (
                                    <MenuItem
                                        value={ STATES[state] }
                                        key={ index }
                                    >
                                        { STATES[state] }
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item mobile={ 12 } tablet={ 4 }>
                        <MaskedTextField
                            mask="99999"
                            value={ state.zip }
                            onChange={ handleChange('zip') }
                            label="Zip code*"
                            sx={{ width: '100%' }}
                            inputProps={{ maxLength: 255 }}
                            error={ (state.zip === '' && !!emptyFieldError)
                                || !!zipError
                            }
                        />
                    </Grid>
                    <Grid item mobile={ 12 } tablet={ 6 }>
                        <MaskedTextField
                            mask="999-999-9999"
                            value={ state.workPhone }
                            onChange={ handleChange('workPhone') }
                            label="Work phone*"
                            sx={{ width: '100%' }}
                            inputProps={{ maxLength: 255 }}
                            error={
                                (state.workPhone === ''
                                    && !!emptyFieldError
                                ) || !!phoneError
                            }
                        />
                    </Grid>
                    <Grid item mobile={ 12 } tablet={ 6 }>
                        <TextField
                            type="number"
                            label="Monthly income*"
                            value={ state.monthlyIncome }
                            sx={{ width: '100%' }}
                            inputProps={{ maxLength: 255 }}
                            onChange={ handleChange('monthlyIncome') }
                            error={
                                (state.monthlyIncome === ''
                                    && !!emptyFieldError
                                ) || !!incomeError
                            }
                        />
                    </Grid>
                    <Grid item mobile={ 12 } tablet={ 6 }>
                        <FormControl fullWidth>
                            <InputLabel>
                                Payment type*
                            </InputLabel>
                            <Select
                                value={ state.paymentType }
                                label="Payment type*"
                                onChange={ handleChange('paymentType') }
                                sx={ styles.select }
                            >
                                { Object.keys(INCOME_TYPE).map((
                                    type: string,
                                    index: number,
                                ) => (
                                    <MenuItem
                                        value={ type }
                                        key={ index }
                                    >
                                        { INCOME_TYPE[type] }
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item mobile={ 12 } tablet={ 6 }>
                        <FormControl fullWidth>
                            <InputLabel>
                                Pay frequency*
                            </InputLabel>
                            <Select
                                value={ state.payFrequency }
                                label="Pay frequency*"
                                onChange={ handleChange('payFrequency') }
                                sx={ styles.select }
                            >
                                { PAY_FREQUENCIES.map((
                                    frequency: string,
                                    index: number,
                                ) => (
                                    <MenuItem
                                        value={ frequency }
                                        key={ index }
                                    >
                                        { frequency }
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    { defineFrequencyOptions(state.payFrequency) }
                </Grid>
            </Box>
        </Box>
        <Box sx={ styles.buttonsContainer }>
            { isDesktop
                ? <React.Fragment>
                    <Button
                        variant="outlined"
                        sx={ styles.cancelButton }
                        onClick={ onClose }
                        data-testid="btn-cancel"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={ styles.saveButton }
                        onClick={ handleSaveChanges }
                        data-testid="btn-save"
                    >
                        Save Changes
                    </Button>
                </React.Fragment>
                : <React.Fragment>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={ styles.saveButton }
                        onClick={ handleSaveChanges }
                        data-testid="btn-save"
                    >
                        Save Changes
                    </Button>
                    <Button
                        variant="outlined"
                        sx={ styles.cancelButton }
                        onClick={ onClose }
                        data-testid="btn-cancel"
                    >
                        Cancel
                    </Button>
                </React.Fragment>
            }
        </Box>
    </Box>;
};

export default EmploymentInfoForm;
