import React, { FunctionComponent, useState } from 'react';
import { EditInfoFormProps, styles } from './PersonalInfoForm';
import Box from '@mui/material/Box';
import { DesktopDialogTitle } from '../../../DialogPaperContent';
import Button from '@mui/material/Button';
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KnownBreakpoints } from '../../../../common/constants';
import { ApiClient, isOffline } from '../../../../common/helpers';
import useAuthorized from '../../../../common/hooks/useAuthorized';
import { Alert, Checkbox, FormControlLabel } from '@mui/material';
import WarningIcon from '../../../../assets/SvgIcons/WarningIcon';
import { Loader } from '../../PageLoader';
import asyncLogger from '../../../../common/logger';
import OfflineError from '../../../OfflineError';

const ChangePasswordForm: FunctionComponent<EditInfoFormProps> = props => {
    const authUser = useAuthorized();
    const {
        onClose,
        onSuccess,
        onError,
        content,
    } = props;
    const theme = useTheme<Theme>();
    const isDesktop = useMediaQuery(
        theme.breakpoints.up(KnownBreakpoints.tablet),
    );
    const [subscriptions, setSubscriptions] =
        useState<{
            id: number,
            name:
            string,
            isSubscribed: boolean,
            disabled: boolean,
            withIndent: boolean,
        }[]>(
            Object.keys(content).map(p => ({
                name: p,
                id: (content[p] as any).id,
                isSubscribed: (content[p] as any).value,
                disabled: (content[p] as any).disabled,
                withIndent: (content[p] as any).withIndent,
            })),
        );
    const [submitError, setSubmitError] =
        useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [networkError, setNetworkError] = useState(false);

    const handleSaveChanges = async(): Promise<void> => {
        if (!authUser) {
            return ;
        }

        if (submitError) {
            return ;
        }

        setLoading(true);

        try {
            await ApiClient.request('/updateSubscriptions', {
                body: {
                    subscriptions: subscriptions.map(p => ({
                        id: p.id,
                        isSubscribed: p.isSubscribed,
                    })),
                },
            });

            onSuccess?.({});
            onClose?.();
        } catch (err) {
            const isNetError = isOffline(err);
            setNetworkError(isNetError);

            if (!isNetError) {
                setSubmitError('Updating Attempt Error');
                asyncLogger.error(err);
                onError?.(err);
            }
        }

        setLoading(false);

        return;
    };

    if (loading) {
        return <Loader />;
    }

    return <Box sx={ styles.layout }>
        { networkError &&
            <OfflineError onClose={ () => setNetworkError(false) } />
        }
        <Box>
            { isDesktop && <DesktopDialogTitle
                title={ 'Message Subscriptions' }
                subtitle={ 'You can subscribe/unsubscribe from messages here' }
            /> }
            <Box marginTop={ isDesktop ? '35px' : '45px' }>
                { (submitError) &&
                  <Alert
                        severity={ 'error' }
                        iconMapping={{
                            error: <WarningIcon/>,
                        }}
                    >
                        { submitError }
                   </Alert> }
                {subscriptions.map(p => (
                    <Box
                        key={ p.name }
                        sx={{ paddingLeft: p.withIndent ? '20px' : '0' }}
                    >
                        <FormControlLabel
                            label={ p.name }
                            control={
                                <Checkbox
                                    checked={ p.isSubscribed }
                                    disabled={ p.disabled }
                                    onChange= { e => {
                                        p.isSubscribed = e.target.checked;
                                        setSubscriptions([ ...subscriptions ]);
                                    } }
                                />
                            }
                        />
                    </Box>
                ))}
            </Box>
        </Box>
        <Box sx={ styles.buttonsContainer }>
            { isDesktop
                ? <React.Fragment>
                    <Button
                        variant="outlined"
                        sx={ styles.cancelButton }
                        onClick={ onClose }
                        data-testid="btn-cancel"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={ styles.saveButton }
                        onClick={ handleSaveChanges }
                        data-testid="btn-update-subscriptions"
                    >
                        Update Subscriptions
                    </Button>
                </React.Fragment>
                : <React.Fragment>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={ styles.saveButton }
                        onClick={ handleSaveChanges }
                        data-testid="btn-update-subscriptions"
                    >
                        Update Subscriptions
                    </Button>
                    <Button
                        variant="outlined"
                        sx={ styles.cancelButton }
                        onClick={ onClose }
                        data-testid="btn-cancel"
                    >
                        Cancel
                    </Button>
                </React.Fragment>
            }
        </Box>
    </Box>;
};

export default ChangePasswordForm;
