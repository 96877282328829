import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { isDark } from '../../common/helpers';

function DoneGreenIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={'0 0 18 18'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
        >
            <path
                d={
                    'M7.6725 13.095L14.04 6.7275L13.005 5.715L7.6725 11.0475L4.9725 8.3475L3.96 9.36L7.6725 13.095ZM9 18C7.77 18 6.6075 17.7638 5.5125 17.2912C4.4175 16.8187 3.46125 16.1737 2.64375 15.3562C1.82625 14.5387 1.18125 13.5825 0.70875 12.4875C0.23625 11.3925 0 10.23 0 9C0 7.755 0.23625 6.585 0.70875 5.49C1.18125 4.395 1.82625 3.4425 2.64375 2.6325C3.46125 1.8225 4.4175 1.18125 5.5125 0.70875C6.6075 0.23625 7.77 0 9 0C10.245 0 11.415 0.23625 12.51 0.70875C13.605 1.18125 14.5575 1.8225 15.3675 2.6325C16.1775 3.4425 16.8187 4.395 17.2912 5.49C17.7638 6.585 18 7.755 18 9C18 10.23 17.7638 11.3925 17.2912 12.4875C16.8187 13.5825 16.1775 14.5387 15.3675 15.3562C14.5575 16.1737 13.605 16.8187 12.51 17.2912C11.415 17.7638 10.245 18 9 18Z'
                }
                fill={ isDark(theme)
                    ? theme.palette.primary.dark
                    : theme.palette.primary.main
                }
            />
        </SvgIcon>
    );
}

export default DoneGreenIcon;
