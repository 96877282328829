import React, { FunctionComponent } from 'react';
import { Theme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { FormControlLabel } from '@mui/material';

const styles = {
    labelText: (theme: Theme) => ({
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '30px',
        color: theme.palette.text.primary,
    }),
    switchContainer: {
        marginRight: 'unset',
        paddingLeft: '10px',
    },
};

export interface ValueSwitchProps {
    title: string;
    value: boolean;
    onClick: () => void;
    disabled?: boolean;
}

const ValueSwitch: FunctionComponent<ValueSwitchProps> = props => {
    const { title, value, onClick, disabled } = props;

    return <FormControlLabel
        sx={ styles.switchContainer }
        control={<Switch
            checked={ value }
            onClick={ onClick }
            value={ value }
            disabled={ disabled || false }
        />}
        label={ <Typography sx={ styles.labelText }>{ title }</Typography> }
    />;
};

export default ValueSwitch;
