import React, { FunctionComponent, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { DesktopDialogTitle } from '../../../DialogPaperContent';
import {
    ApiClient,
    isLight,
    isOffline,
    RestMethod,
} from '../../../../common/helpers';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    KnownBreakpoints,
    THEME_BREAKPOINTS,
} from '../../../../common/constants';
import { Loader } from '../../PageLoader';
import OfflineError from '../../../OfflineError';
import Typography from '@mui/material/Typography';
import ValueSwitch from '../ValueSwitch';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useCustomer } from '../../../../common/hooks/AccountInfoProvider';
import useAuthorized from '../../../../common/hooks/useAuthorized';
import { Customer } from '../../../../common/types/Customer';
import { Alert } from '@mui/material';
import WarningIcon from '../../../../assets/SvgIcons/WarningIcon';
import asyncLogger from '../../../../common/logger';
import { EditInfoFormProps } from './PersonalInfoForm';
import InfoVerification, { VerificationInfo } from '../InfoVerification';
import { VerificationMethods } from '../../../../config/membersArea';
import VerificationByCodeForm
    from '../../EntityVerification/VerificationByCodeForm';
import {
    biometricLoginAllowed,
    isBiometricLoginEnabled,
    SaveBiometricResponse,
    toggleBiometricLogin,
} from '../../../../common/helpers/biometricLogin';
import { useConfig } from '../../../../common/hooks/ConfigProvider';

const styles = {
    layout: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    buttonsContainer: (theme: Theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            marginTop: '40px',
            marginBottom: '20px',
            flexDirection: 'column',
            gap: '20px',
            justifyContent: 'center',
        },
    }),
    cancelButton: (theme: Theme) => ({
        width: '26%',
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            width: '100%',
        },
    }),
    saveButton: (theme: Theme) => ({
        width: '38%',
        backgroundColor: isLight(theme)
            ? theme.palette.secondary.main
            : theme.palette.primary.main
        ,
        '&:hover': {
            backgroundColor: isLight(theme)
                ? theme.palette.secondary.light
                : theme.palette.primary.light
            ,
        },
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            width: '100%',
        },
    }),
    row: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
    },
    contentContainer: (theme: Theme) => ({
        marginTop: '10px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        marginBottom: '30px',
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            marginTop: '45px',
        },
    }),
    text: {
        fontSize: '16px',
        fontWeight: 500,
    },
    selectContainer: {
        width: '50%',
    },
    select: (theme: Theme) => ({
        '& .MuiOutlinedInput-input' : { fontSize: theme.typography.fontSize },
    }),
    half: {
        width: '50%',
        display: 'flex',
        gap: '10px',
        flexWrap: 'wrap',
    },
};

const MFAInfoForm: FunctionComponent<EditInfoFormProps> = props => {
    const authUser = useAuthorized();
    const { content, onClose, onSuccess, onError } = props;
    const { consumerName } = useConfig();
    const theme = useTheme<Theme>();
    const isDesktop = useMediaQuery(
        theme.breakpoints.up(THEME_BREAKPOINTS[KnownBreakpoints.tablet]),
    );
    const inputCustomer = useCustomer();
    const initialMFAEnabled = content['2-factor verification'] === 'Activated';
    const initialMFAMethod = content['2-factor verification method'] === '—'
        ? 'Email'
        : content['2-factor verification method'] as string;
    const [networkError, setNetworkError] = useState(false);
    const [
        biometricEnabled,
        setBiometricEnabled,
    ] = useState(isBiometricLoginEnabled());
    const [mfaEnabled, setMFAEnabled] = useState<boolean>(initialMFAEnabled);
    const [mfaMethod, setMFAMethod] = useState<string>(initialMFAMethod);
    const [loading, setLoading] = useState<boolean>(false);
    const [saveError, setSaveError] = useState<string | undefined>(undefined);
    const [customer, setCustomer] = useState<Customer | undefined>(undefined);
    const [disableSave, setDisableSave] = useState<boolean>(false);
    const [
        verificationInfo,
        setVerificationInfo,
    ] = useState<VerificationInfo | undefined>(undefined);
    const cellPhone = customer?.phones?.find(
        (phone: any) => phone.type === 'Cell',
    );
    const cellPhoneNumber = cellPhone?.phoneNumber;
    const email = customer?.email;
    const phoneVerified = cellPhone?.verified;
    const emailVerified = customer?.emailVerified;

    useEffect(() => {
        if (!authUser || !inputCustomer) {
            return ;
        }

        setLoading(true);

        (async () => {
            await load();
        })();
        // eslint-disable-next-line
    }, []);

    const load = async() => {
        try {
            const { data: { customer } } = await ApiClient.request(
                '/customerInfo',
                {
                    method: RestMethod.GET,
                    query: { customerId: String(inputCustomer?.id) },
                    withAuth: true,
                },
            );

            if (!customer) {
                setDisableSave(true);
                setSaveError('Cannot load your information!');
            }

            setCustomer(customer);
            setLoading(false);

            return ;
        } catch (err) {
            asyncLogger.error(err);
            window.open('/error/401', '_self');
        }
    };

    const handleMethodChange = (event: SelectChangeEvent) => {
        setVerificationInfo(undefined);
        setSaveError(undefined);
        setMFAMethod(event.target.value);
    };

    const handleStartVerification = (info: VerificationInfo) => {
        setVerificationInfo(info);
    };

    const handleCompleteVerification = async() => {
        setVerificationInfo(undefined);
        setSaveError(undefined);
        setLoading(true);

        await load();
    };

    const handleMFASave = async() => {
        if (!customer) {
            return ;
        }

        if (mfaEnabled !== initialMFAEnabled
            || mfaMethod !== initialMFAMethod
        ) {
            const { errors } = await ApiClient.request<{
                data: { updateCustomer: Customer };
                errors: { message: string }[];
            }>('/updateCustomer', {
                body: {
                    input: {
                        id: String(customer.id),
                        mfa: {
                            enabled: mfaEnabled,
                            methods: [mfaMethod],
                        },
                    },
                    withAuth: true,
                },
            });

            const [error] = errors || [];

            return error;
        }
    };

    const handleBiometricSave = async(): Promise<SaveBiometricResponse> => {
        if (!customer || !email) {
            return {
                completed: false,
                error: `Undefined ${ !email ? 'email' : 'customer' }!`,
            };
        }

        if (biometricEnabled === isBiometricLoginEnabled()) {
            return { completed: true };
        }

        return await toggleBiometricLogin(
            biometricEnabled,
            {
                consumerName,
                email,
                customerId: customer.id,
            },
        );
    };

    const handleSave = async(): Promise<void> => {
        if (saveError || !authUser || !customer) {
            return ;
        }

        if (mfaEnabled) {
            if (mfaMethod === 'Email' && !emailVerified) {
                setSaveError('Email address must be verified!');

                return ;
            }

            if (mfaMethod === 'Phone' && !phoneVerified) {
                setSaveError('Cell phone number must be verified!');

                return ;
            }
        }

        const mfaSaveError = 'An error occurred while updating your 2-factor '
            + 'verification information!';
        const biometricSaveError = 'An error occurred while updating your '
            + 'biometric login information!';

        setLoading(true);

        try {
            const error = await handleMFASave();

            if (error) {
                asyncLogger.error(error?.message);

                setSaveError(mfaSaveError);
                setBiometricEnabled(isBiometricLoginEnabled());
                setLoading(false);

                return ;
            }

            if (!biometricLoginAllowed()) {
                onSuccess?.();
                onClose?.();
            }
        } catch (err) {
            const isNetError = isOffline(err);
            setNetworkError(isNetError);

            if (!isNetError) {
                setSaveError(mfaSaveError);

                asyncLogger.error(err);

                onError?.(err);
            }

            setLoading(false);

            return ;
        }

        try {
            if (biometricLoginAllowed()) {
                const { completed, error } = await handleBiometricSave();

                if (!completed) {
                    setSaveError(error ? error : biometricSaveError);
                    setBiometricEnabled(isBiometricLoginEnabled());
                } else {
                    onSuccess?.();
                    onClose?.();
                }
            }
        } catch (err) {
            setSaveError(biometricSaveError);
            setBiometricEnabled(isBiometricLoginEnabled());

            asyncLogger.error(err);
        }

        setLoading(false);

        return ;
    };

    if (loading) {
        return <Loader />;
    }

    return <Box sx={ styles.layout }>
        { networkError &&
            <OfflineError onClose={ () => setNetworkError(false) } />
        }
        <Box>
            { isDesktop && <DesktopDialogTitle
                title={ 'Edit Two-Factor Verification Information' }
            /> }
            <Box sx={ styles.contentContainer }>
                { saveError && <Alert
                    severity={ 'error' }
                    iconMapping={ {
                        error: <WarningIcon/>,
                    } }
                >{ saveError }</Alert> }
                { biometricLoginAllowed() && <Box sx={ styles.row }>
                    <Box sx={ styles.half }>
                        <Typography component="h5" sx={ styles.text }>
                            Biometric login
                        </Typography>
                    </Box>
                    <Box sx={ styles.half }>
                        <ValueSwitch
                            title={ 'Activated' }
                            value={ biometricEnabled }
                            onClick={ () => {
                                setBiometricEnabled(prevState => !prevState);
                                setSaveError(undefined);
                            }}
                            disabled={ disableSave }
                        />
                    </Box>
                </Box> }
                <Box sx={ styles.row }>
                    <Box sx={ styles.half }>
                        <Typography component="h5" sx={ styles.text }>
                            2-factor verification
                        </Typography>
                    </Box>
                    <Box sx={ styles.half }>
                        <ValueSwitch
                            title={ 'Activated' }
                            value={ mfaEnabled }
                            onClick={ () => {
                                setMFAEnabled(prevState => !prevState);
                                setSaveError(undefined);
                            }}
                            disabled={ disableSave }
                        />
                    </Box>
                </Box>
                <Box sx={ styles.row }>
                    <Box sx={ styles.half }>
                        <Typography component="h5" sx={ styles.text }>
                            2-factor verification method
                        </Typography>
                    </Box>
                    <Box sx={ styles.half }>
                        <FormControl sx={ styles.selectContainer }>
                            <InputLabel>Select method</InputLabel>
                            <Select
                                value={ mfaMethod as '' }
                                onChange={ handleMethodChange }
                                label={ 'Select method' }
                                sx={ styles.select }
                                disabled={ !mfaEnabled || disableSave }
                            >
                                <MenuItem value={ 'Email' }>
                                    Email
                                </MenuItem>
                                <MenuItem value={ 'Phone' }>
                                    Phone
                                </MenuItem>
                            </Select>
                        </FormControl>
                        { customer && <InfoVerification
                            value={ mfaMethod === VerificationMethods.Email
                                ? email || ''
                                : cellPhoneNumber || ''
                            }
                            entity={ mfaMethod === VerificationMethods.Email
                                ? VerificationMethods.Email || ''
                                : VerificationMethods.Phone || ''
                            }
                            customerId={ customer.id }
                            verified={ mfaMethod === VerificationMethods.Email
                                ? emailVerified || false
                                : phoneVerified || false
                            }
                            displayValue={ false }
                            setNetworkError={ setNetworkError }
                            onSuccess={ handleStartVerification }
                        /> }
                    </Box>
                </Box>
            </Box>
            { verificationInfo && <VerificationByCodeForm
                { ...verificationInfo }
                onSuccess={ handleCompleteVerification }
                displayTitle={ false }
                isMFAEditForm
            /> }
        </Box>
        <Box sx={ styles.buttonsContainer }>
            { isDesktop
                ? <React.Fragment>
                    <Button
                        variant="outlined"
                        sx={ styles.cancelButton }
                        data-testid="btn-cancel"
                        onClick={ onClose as any }
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={ styles.saveButton }
                        data-testid="btn-save"
                        onClick={ handleSave }
                        disabled={ disableSave }
                    >
                        Save Changes
                    </Button>
                </React.Fragment>
                : <React.Fragment>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={ styles.saveButton }
                        data-testid="btn-save"
                        onClick={ handleSave }
                        disabled={ disableSave }
                    >
                        Save Changes
                    </Button>
                    <Button
                        variant="outlined"
                        sx={ styles.cancelButton }
                        data-testid="btn-cancel"
                        onClick={ onClose as any }
                    >
                        Cancel
                    </Button>
                </React.Fragment>
            }
        </Box>
    </Box>;
};

export default MFAInfoForm;
