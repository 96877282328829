import React, { FunctionComponent, useState } from 'react';
import { EditFormProps, styles } from './PersonalInfoForm';
import Box from '@mui/material/Box';
import { DesktopDialogTitle } from '../../../DialogPaperContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KnownBreakpoints } from '../../../../common/constants';
import PasswordField from '../../../Form/PasswordField';
import { SelectChangeEvent } from '@mui/material/Select';
import { ApiClient, isOffline } from '../../../../common/helpers';
import useAuthorized from '../../../../common/hooks/useAuthorized';
import { APPLICATION_PASSWORD_LENGTH } from '../../../../config/membersArea';
import { RX_PASSWORD } from '../../../../common/helpers/validation';
import { Alert } from '@mui/material';
import WarningIcon from '../../../../assets/SvgIcons/WarningIcon';
import { Loader } from '../../PageLoader';
import asyncLogger from '../../../../common/logger';
import OfflineError from '../../../OfflineError';

interface ChangePasswordState {
    oldPassword: string;
    newPassword: string;
    repeatPassword: string;
}

const ChangePasswordForm: FunctionComponent<EditFormProps> = props => {
    const authUser = useAuthorized();

    const { onClose, onSuccess, onError, openAlert } = props;

    const theme = useTheme<Theme>();
    const isDesktop = useMediaQuery(
        theme.breakpoints.up(KnownBreakpoints.tablet),
    );

    const [state, setState] = useState<ChangePasswordState>({
        oldPassword: '',
        newPassword: '',
        repeatPassword: '',
    });
    const [
        emptyFieldError,
        setEmptyFieldError,
    ] = useState<string | undefined>(undefined);
    const [
        validationError,
        setValidationError,
    ] = useState<string | undefined>(undefined);
    const [
        equalityError,
        setEqualityError,
    ] = useState<string | undefined>(undefined);
    const [
        submitError,
        setSubmitError,
    ] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [networkError, setNetworkError] = useState(false);

    const handleChange = (prop: string) => (
        event: React.ChangeEvent<
            HTMLElement & { value: any }
            > | SelectChangeEvent,
    ) => {
        setSubmitError(undefined);
        setEmptyFieldError(undefined);
        setValidationError(undefined);
        setEqualityError(undefined);

        setState({ ...state, [prop]: event.target.value });
    };

    const handleSaveChanges = async(): Promise<void> => {
        if (!authUser) {
            return ;
        }

        if (submitError) {
            return ;
        }

        if (state.oldPassword === ''
            || state.newPassword === ''
            || state.repeatPassword === ''
        ) {
            setEmptyFieldError('Required');

            return ;
        }

        if (state.newPassword.length < APPLICATION_PASSWORD_LENGTH) {
            setValidationError('New password must be at least 8 characters');

            return ;
        }

        if (!RX_PASSWORD.test(state.newPassword)) {
            setValidationError('Password must contain a letter and a number');

            return ;
        }

        if (state.newPassword !== state.repeatPassword) {
            setEqualityError('Passwords are not the same');

            return ;
        }

        setLoading(true);

        try {
            const { data } = await ApiClient.request<{
                data: { changeCustomerPassword: boolean }
            }>('/changePassword', {
                body: {
                    currentPassword: state.oldPassword,
                    newPassword: state.newPassword,
                },
            });

            if (data?.changeCustomerPassword === true) {
                onSuccess?.(data);
                onClose?.();
                openAlert?.();
            } else {
                setSubmitError('Updating Attempt Error. Please make sure '
                    + 'that old password is correct!',
                );
            }
        } catch (err) {
            const isNetError = isOffline(err);
            setNetworkError(isNetError);

            if (!isNetError) {
                setSubmitError(
                    'Updating Attempt Error. Please make sure '
                    + 'that old password is correct!',
                );

                asyncLogger.error(err);

                onError?.(err);
            }
        }

        setLoading(false);

        return ;
    };

    if (loading) {
        return <Loader />;
    }

    return <Box sx={ styles.layout }>
        { networkError &&
            <OfflineError onClose={ () => setNetworkError(false) } />
        }
        <Box>
            { isDesktop && <DesktopDialogTitle
                title={ 'Change Password' }
                subtitle={ 'After changing password you will '
                    + 'be redirected to the Home page.'
                }
            /> }
            <Box marginTop={ isDesktop ? '35px' : '45px' }>
                <Grid container spacing={ 1 }>
                    <Grid item mobile={ 12 } tablet={ 6.1 }>
                        { (emptyFieldError
                            || validationError
                            || equalityError
                            || submitError
                        ) && <Alert
                            severity={ 'error' }
                            iconMapping={{
                                error: <WarningIcon/>,
                            }}
                        >
                            { emptyFieldError
                                ? emptyFieldError
                                : validationError
                                    ? validationError
                                    : equalityError
                                        ? equalityError
                                        : submitError
                            }
                        </Alert> }
                        <PasswordField
                            id="old-password"
                            label="Old password*"
                            value={ state.oldPassword }
                            sx={{ width: '100%' }}
                            inputProps={{ maxLength: 255 }}
                            onChange={ handleChange('oldPassword') }
                            error={
                                state.oldPassword === '' && !!emptyFieldError
                            }
                        />
                    </Grid>
                    <Grid item mobile={ 12 } tablet={ 6.1 }>
                        <PasswordField
                            id="new-password"
                            label="New password*"
                            value={ state.newPassword }
                            sx={{ width: '100%' }}
                            inputProps={{ maxLength: 255 }}
                            onChange={ handleChange('newPassword') }
                            error={
                                (state.newPassword === '' && !!emptyFieldError)
                                || !!validationError
                                || !!equalityError
                            }
                        />
                    </Grid>
                    <Grid item mobile={ 12 } tablet={ 6.1 }>
                        <PasswordField
                            id="repeat-password"
                            label="Repeat new password*"
                            value={ state.repeatPassword }
                            sx={{ width: '100%' }}
                            inputProps={{ maxLength: 255 }}
                            onChange={ handleChange('repeatPassword') }
                            error={
                                (state.repeatPassword === ''
                                    && !!emptyFieldError
                                ) || !!equalityError
                            }
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Box sx={ styles.buttonsContainer }>
            { isDesktop
                ? <React.Fragment>
                    <Button
                        variant="outlined"
                        sx={ styles.cancelButton }
                        onClick={ onClose }
                        data-testid="btn-cancel"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={ styles.saveButton }
                        onClick={ handleSaveChanges }
                        data-testid="btn-change-password"
                    >
                        Change password
                    </Button>
                </React.Fragment>
                : <React.Fragment>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={ styles.saveButton }
                        onClick={ handleSaveChanges }
                        data-testid="btn-change-password"
                    >
                        Change password
                    </Button>
                    <Button
                        variant="outlined"
                        sx={ styles.cancelButton }
                        onClick={ onClose }
                        data-testid="btn-cancel"
                    >
                        Cancel
                    </Button>
                </React.Fragment>
            }
        </Box>
    </Box>;
};

export default ChangePasswordForm;
