import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material/styles';
import SecureIcon from '../../assets/SvgIcons/SecureIcon';
import { isLight } from '../../common/helpers';
import { KnownBreakpoints } from '../../common/constants';
import Norton from '/public/Norton.png';

export const styles = {
    titleContainer: (theme: Theme) => ({
        display: 'flex',
        alignItems: 'center',
        color: isLight(theme)
            ? theme.palette.text.primary
            : theme.palette.secondary.dark
        ,
        flexWrap: 'wrap',
        rowGap: '5px',
    }),
    title: (theme: Theme) => ({
        ...theme.typography.h6,
        lineHeight: '20px',
        paddingRight: '7.5px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            ...theme.typography.h5,
        },
    }),
    titleIcon: {
        fontSize: '20px',
    },
    text: (theme: Theme) => ({
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '22px',
        color: isLight(theme)
            ? theme.palette.text.primary
            : theme.palette.secondary.dark
        ,
        marginTop: '10px',
    }),
};

const SecurityGuaranteeInfo: FunctionComponent<
    { withNortonImage?: boolean }
> = props => {
    const { withNortonImage } = props;

    const theme = useTheme<Theme>();

    return <React.Fragment>
        <Box sx={ styles.titleContainer }>
            <Typography component="h6" sx={ styles.title }>
                Security guarantee
            </Typography>
            <SecureIcon sx={ styles.titleIcon } fill={ isLight(theme)
                ? theme.palette.text.primary
                : theme.palette.secondary.dark
            }/>
        </Box>
        <Typography sx={ styles.text }>
            We use 128-bit protection and strict, high levels of security
            & encryption standards to keep your information safe.
        </Typography>
        { withNortonImage && <React.Fragment>
            <Typography sx={ styles.text }>
                Your security is assured using industry standard practices.
            </Typography>
            <img
                style={{ height: '40px', marginTop: '10px' }}
                src={ Norton.src }
                alt="Verify"
            />
        </React.Fragment> }
    </React.Fragment>;
};

export default SecurityGuaranteeInfo;
