import React, { FunctionComponent, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material/styles';
import { ApiClient, isLight, isOffline } from '../../../common/helpers';
import DoneGreenIcon from '../../../assets/SvgIcons/DoneGreenIcon';
import ProgressWarnIcon from '../../../assets/SvgIcons/ProgressWarnIcon';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KnownBreakpoints } from '../../../common/constants';
import { Tooltip } from '@mui/material';
import { NamedRoute } from '../../../common/hooks/useNamedRoute';
import asyncLogger from '../../../common/logger';
import { useRouter } from 'next/router';

const styles = {
    container: (theme: Theme, verified: boolean) => ({
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        cursor: verified ? 'auto' : 'pointer',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            justifyContent: 'flex-end',
            overflow: 'hidden',
        },
    }),
    subContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    text: (theme: Theme) => ({
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '30px',
        color: theme.palette.text.primary,
        marginRight: '10px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            marginRight: 'unset',
            marginLeft: '10px',
            fontWeight: 700,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    }),
    textSuccess: (theme: Theme) => ({
        fontSize: theme.typography.fontSize,
        fontWeight: 700,
        lineHeight: '30px',
        color: isLight(theme)
            ? theme.palette.primary.main
            : theme.palette.primary.dark
        ,
    }),
    textRequire: (theme: Theme) => ({
        fontSize: theme.typography.fontSize,
        fontWeight: 700,
        lineHeight: '30px',
        color: theme.palette.warning.dark,
    }),
    icon: (spin: boolean) => ({
        fontSize: '22px',
        marginLeft: '4px',
        animation: spin
            ? '2s linear 0s infinite normal none running spin'
            : 'none'
        ,
    }),
};

export interface VerificationInfo {
    entity: string;
    token: string;
    customerId: string;
    value: string;
}

export interface InfoVerificationProps {
    value: string;
    entity: string;
    customerId: string;
    verified: boolean;
    displayValue: boolean;
    setNetworkError: (error: boolean) => void;
    onSuccess?: (info: VerificationInfo) => void;
}

const SuccessInfoVerification: FunctionComponent = () => {
    return <Box sx={ styles.subContainer }>
        <Typography sx={ styles.textSuccess }>
            Verified
        </Typography>
        <DoneGreenIcon sx={ styles.icon(false) }/>
    </Box>;
};

const RequireInfoVerification: FunctionComponent<{ loading: boolean }> = (
    { loading },
) => {
    return <Box sx={ styles.subContainer }>
        <Typography sx={ styles.textRequire }>
            Verify...
        </Typography>
        <ProgressWarnIcon sx={ styles.icon(loading) }/>
    </Box>;
};

const InfoVerification: FunctionComponent<InfoVerificationProps> = props => {
    const router = useRouter();

    const {
        verified,
        value,
        entity,
        customerId,
        displayValue,
        setNetworkError,
        onSuccess,
    } = props;

    const theme = useTheme<Theme>();
    const isDesktop = useMediaQuery(
        theme.breakpoints.up(KnownBreakpoints.tablet),
    );

    const [loading, setLoading] = useState(false);

    function createVerifyUrl(
        entity: string,
        token: string,
        customerId: string,
        value: string,
    ): string {
        const domain = window?.origin;

        return domain
            + NamedRoute.VERIFY_ENTITY
            + `?entity=${ entity }&value=${ value }`
            + `&customerId=${ customerId }&t=${ token }`;
    }

    const handleVerify = async() => {
        if (verified) {
            return ;
        }

        setLoading(true);

        try {
            const headerDomain = {
                'x-ws-domain': window?.origin,
            } as any;

            const { data: {
                startEntityVerification,
            }} = await ApiClient.request('/startEntityVerification', {
                body: {
                    customerId,
                    entity: value,
                    entityType: entity,
                },
                withAuth: false,
                headers: headerDomain,
            });

            const token = startEntityVerification;

            if (token !== '') {
                const url = createVerifyUrl(
                    entity,
                    token,
                    customerId,
                    value,
                );

                if (onSuccess) {
                    onSuccess({ entity, token, customerId, value });
                } else {
                    await router.push(url);
                }
            }
        } catch (err) {
            setNetworkError(isOffline(err));
            asyncLogger.error(err);
        } finally {
            setLoading(false);
        }
    };

    return <Box
        sx={ styles.container(theme, verified) }
        onClick={ handleVerify }
    >
        { isDesktop
            ? <React.Fragment>
                { displayValue && <Typography sx={ styles.text }>
                    { value }
                </Typography> }
                { verified
                    ? <SuccessInfoVerification/>
                    : <RequireInfoVerification loading={ loading }/>
                }
            </React.Fragment>
            : <React.Fragment>
                { verified
                    ? <SuccessInfoVerification />
                    : <RequireInfoVerification loading={ loading }/>
                }
                { displayValue && <Tooltip
                    title={ value }
                    enterTouchDelay={ 0 }
                >
                    <Typography sx={ styles.text }>
                        { value }
                    </Typography>
                </Tooltip> }
            </React.Fragment>
        }
    </Box>;
};

export default InfoVerification;
