import React, { FunctionComponent, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import { DesktopDialogTitle } from '../../../DialogPaperContent';
import { Theme } from '@mui/material/styles';
import { ApiClient, isLight, isOffline } from '../../../../common/helpers';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KnownBreakpoints } from '../../../../common/constants';
import {
    formatNullableScalar,
    formatUpdatedFieldsToEmailText,
} from '../../../../common/helpers/format';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {
    PROFILE_INFO_UPDATE_SUBJECT,
    STATES,
    US_DATE_FORMAT,
} from '../../../../config/membersArea';
import useAuthorized from '../../../../common/hooks/useAuthorized';
import { getKeyByValue } from '../../../../common/helpers/getKeyByValue';
import WarningIcon from '@mui/icons-material/Warning';
import Alert from '@mui/material/Alert';
import { isDateOfBirth } from '../../../../common/helpers/validation';
import { Loader } from '../../PageLoader';
import MaskedTextField from '../../../MaskedTextField';
import isAdult from '../../../../common/helpers/isAdult';
import asyncLogger from '../../../../common/logger';
import OfflineError from '../../../OfflineError';
import {
    useCustomer,
} from '../../../../common/hooks/AccountInfoProvider';

export const styles = {
    layout: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    buttonsContainer: (theme: Theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            marginTop: '40px',
            marginBottom: '20px',
            flexDirection: 'column',
            gap: '20px',
            justifyContent: 'center',
        },
    }),
    cancelButton: (theme: Theme) => ({
        width: '26%',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            width: '100%',
        },
    }),
    saveButton: (theme: Theme) => ({
        width: '38%',
        backgroundColor: isLight(theme)
            ? theme.palette.secondary.main
            : theme.palette.primary.main
        ,
        '&:hover': {
            backgroundColor: isLight(theme)
                ? theme.palette.secondary.light
                : theme.palette.primary.light
            ,
        },
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            width: '100%',
        },
    }),
    select: (theme: Theme) => ({
        '& .MuiOutlinedInput-input' : { fontSize: theme.typography.fontSize },
    }),
};

export interface EditInfoFormProps extends EditFormProps {
    content: { [key: string]: string | JSX.Element };
}

export interface EditFormProps {
    onClose?: () => void;
    onSuccess?: (data?: any) => void;
    onError?: (error: any) => void;
    openAlert?: () => void;
    openRequestAlert?: () => void;
}

interface PersonalInfoState {
    ssn: string;
    dateOfBirth: string;
    streetAddress: string;
    city: string;
    state: string;
    zip: string;
    driverLicenseState: string;
    driverLicenseNumber: string;
}

const PersonalInfoForm: FunctionComponent<EditInfoFormProps> = props => {
    const authorized = useAuthorized();
    const {
        content,
        onClose,
        onSuccess,
        onError,
        openRequestAlert,
    } = props;
    const theme = useTheme<Theme>();
    const isDesktop = useMediaQuery(
        theme.breakpoints.up(KnownBreakpoints.tablet),
    );
    const customer = useCustomer();
    const loanId = customer?.currentLoan?.id;
    const [state, setState] = useState<PersonalInfoState>({
        ssn: formatNullableScalar(content['Social security number'] as string),
        dateOfBirth: formatNullableScalar(content['Date of birth'] as string),
        streetAddress: formatNullableScalar(
            content['Street address'] as string,
        ),
        city: formatNullableScalar(content['City'] as string),
        state: formatNullableScalar(content['State'] as string),
        zip: formatNullableScalar(content['Zip code'] as string),
        driverLicenseState: formatNullableScalar(
            content['Driver\'s license state'] as string,
        ),
        driverLicenseNumber: formatNullableScalar(
            content['Driver\'s license number'] as string,
        ),
    });
    const [birthDateError, setBirthDateError] = useState<
        string | undefined
    >(undefined);
    const [zipError, setZipError] = useState<
        string | undefined
    >(undefined);
    const [submitError, setSubmitError] = useState<
        string | undefined
    >(undefined);
    const [loading, setLoading] = useState(false);
    const [
        emptyFieldError,
        setEmptyFieldError,
    ] = useState<string | undefined>(undefined);
    const [networkError, setNetworkError] = useState(false);

    const handleChange = (prop: string) => (
        event: React.ChangeEvent<
            HTMLElement & { value: any }
        > | SelectChangeEvent,
    ) => {
        setEmptyFieldError(undefined);
        setSubmitError(undefined);

        if (prop === 'dateOfBirth') {
            setBirthDateError(undefined);
        }

        if (prop === 'zip') {
            setZipError(undefined);
        }

        setState({ ...state, [prop]: event.target.value });
    };

    const handleSaveChanges = async(): Promise<void> => {
        if (!authorized) {
            return ;
        }

        if (state.city === ''
            || state.dateOfBirth === ''
            || state.driverLicenseNumber === ''
            || state.streetAddress === ''
            || state.zip === ''
        ) {
            setEmptyFieldError('Required');

            return ;
        }

        if (state.dateOfBirth.includes('_')
            || !isDateOfBirth(state.dateOfBirth)
        ) {
            setBirthDateError(() => 'Invalid Date of Birth!');

            return;
        }

        if (!isAdult(state.dateOfBirth)) {
            setBirthDateError(() => 'You must be at least 18 y.o. '
                + 'to apply for the loan!',
            );

            return;
        }

        if (state.zip.includes('_')) {
            setZipError(() => 'Invalid Zip Code!');

            return;
        }

        if (!customer || !customer.id) {
            setSubmitError(
                'Updating Attempt Error. Unable to detect customer! '
                + 'Please reload the page...',
            );

            return ;
        }

        const oldInformation = {
            dateOfBirth: formatNullableScalar(
                content['Date of birth'] as string,
            ),
            streetAddress: formatNullableScalar(
                content['Street address'] as string,
            ),
            city: formatNullableScalar(content['City'] as string),
            state: formatNullableScalar(content['State'] as string),
            zip: formatNullableScalar(content['Zip code'] as string),
            driverLicenseState: formatNullableScalar(
                content['Driver\'s license state'] as string,
            ),
            driverLicenseNumber: formatNullableScalar(
                content['Driver\'s license number'] as string,
            ),
        };

        if (state.dateOfBirth === oldInformation.dateOfBirth
            && state.streetAddress === oldInformation.streetAddress
            && state.city === oldInformation.city
            && state.state === oldInformation.state
            && state.zip === oldInformation.zip
            && state.driverLicenseState === oldInformation.driverLicenseState
            && state.driverLicenseNumber === oldInformation.driverLicenseNumber
        ) {
            onSuccess?.();
            onClose?.();

            return ;
        }

        const personalInfoForUpdate: { [key: string]: any }[] = [];

        if (state.dateOfBirth !== oldInformation.dateOfBirth) {
            personalInfoForUpdate.push({'Date of birth' : state.dateOfBirth});
        }

        if (state.streetAddress !== oldInformation.streetAddress) {
            personalInfoForUpdate.push(
                {'Street address' : state.streetAddress},
            );
        }

        if (state.city !== oldInformation.city) {
            personalInfoForUpdate.push({'City' : state.city});
        }

        if (state.state !== oldInformation.state) {
            personalInfoForUpdate.push({'Home state' : getKeyByValue(
                STATES,
                state.state,
            )});
        }

        if (state.zip !== oldInformation.zip) {
            personalInfoForUpdate.push({'Zip code' : state.zip});
        }

        if (state.driverLicenseState !== oldInformation.driverLicenseState) {
            personalInfoForUpdate.push({
                'Driver\'s license state' : getKeyByValue(
                    STATES,
                    state.driverLicenseState,
                ),
            });
        }

        if (state.driverLicenseNumber !== oldInformation.driverLicenseNumber) {
            personalInfoForUpdate.push(
                {'Driver\'s license number' : state.driverLicenseNumber},
            );
        }

        const emailText = formatUpdatedFieldsToEmailText(
            customer?.id,
            personalInfoForUpdate,
        );

        setLoading(true);

        try {
            const { data } = await ApiClient.request<{
                data: { sendCustomerEmail: boolean }
            }>('/contactUs', {
                body: {
                    formState: {
                        subject: { value: PROFILE_INFO_UPDATE_SUBJECT },
                        message: { value: emailText },
                        loanId: { value: loanId },
                    },
                },
                withAuth: true,
            });

            if (data?.sendCustomerEmail === true) {
                onSuccess?.(data);
                onClose?.();
                openRequestAlert?.();
            } else {
                setSubmitError(
                    'Updating Attempt Error. Please make sure that all data is correct!',
                );
            }
        } catch (err) {
            const isNetError = isOffline(err);
            setNetworkError(isNetError);

            if (!isNetError) {
                setSubmitError(
                    'Updating Attempt Error. Please make sure that all data is correct!',
                );

                asyncLogger.error(err);

                onError?.(err);
            }
        }

        setLoading(false);

        return ;
    };

    if (loading) {
        return <Loader />;
    }

    return <Box sx={ styles.layout }>
        { networkError &&
            <OfflineError onClose={ () => setNetworkError(false) } />
        }
        <Box>
            { isDesktop && <DesktopDialogTitle
                title={ 'Edit Personal Information' }
            /> }
            <Box marginTop={ isDesktop ? '35px' : '45px' }>
                { (birthDateError
                        || zipError
                        || submitError
                        || emptyFieldError
                    ) && <Alert
                        severity={ 'error' }
                        iconMapping={ {
                            error: <WarningIcon/>,
                        } }
                    >
                        { birthDateError
                            ? birthDateError
                            : zipError
                                ? zipError
                                : emptyFieldError
                                    ? emptyFieldError
                                    : submitError
                        }
                    </Alert>
                }
                <Grid container spacing={ 1 }>
                    <Grid item mobile={ 12 } tablet={ 7 }>
                        <TextField
                            label="Social Security Number*"
                            value={ state.ssn }
                            sx={{ width: '100%' }}
                            disabled={ true }
                            onChange={ handleChange('ssn') }
                        />
                    </Grid>
                    <Grid item mobile={ 12 } tablet={ 5 }>
                        <MaskedTextField
                            mask="99/99/9999"
                            value={ state.dateOfBirth }
                            onChange={ handleChange('dateOfBirth') }
                            label={ `Date of birth (${
                                US_DATE_FORMAT.toUpperCase()
                            })*` }
                            sx={{ width: '100%' }}
                            error={ !!birthDateError || (!!emptyFieldError
                                && state.dateOfBirth === ''
                            )}
                        />
                    </Grid>
                    <Grid item mobile={ 12 } tablet={ 12 }>
                        <TextField
                            label="Street address*"
                            value={ state.streetAddress }
                            sx={{ width: '100%' }}
                            onChange={ handleChange('streetAddress') }
                            inputProps={{ maxLength: 255 }}
                            error={ !!emptyFieldError
                                && state.streetAddress === ''
                            }
                        />
                    </Grid>
                    <Grid item mobile={ 12 } tablet={ 4 }>
                        <TextField
                            label="City*"
                            value={ state.city }
                            sx={{ width: '100%' }}
                            onChange={ handleChange('city') }
                            inputProps={{ maxLength: 255 }}
                            error={ !!emptyFieldError && state.city === '' }
                        />
                    </Grid>
                    <Grid item mobile={ 12 } tablet={ 4 }>
                        <FormControl fullWidth>
                            <InputLabel>
                                State*
                            </InputLabel>
                            <Select
                                value={ state.state }
                                label="State*"
                                onChange={ handleChange('state') }
                                sx={ styles.select }
                            >
                                { Object.keys(STATES).map((
                                    state: string,
                                    index: number,
                                ) => (
                                    <MenuItem
                                        value={ STATES[state] }
                                        key={ index }
                                    >
                                        { STATES[state] }
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item mobile={ 12 } tablet={ 4 }>
                        <MaskedTextField
                            mask="99999"
                            value={ state.zip }
                            onChange={ handleChange('zip') }
                            label="Zip code*"
                            sx={{ width: '100%' }}
                            error={ !!zipError
                                || (!!emptyFieldError && state.zip === '' )
                            }
                        />
                    </Grid>
                    <Grid item mobile={ 12 } tablet={ 6 }>
                        <FormControl fullWidth>
                            <InputLabel>
                                { 'Driver\'s license state*' }
                            </InputLabel>
                            <Select
                                value={ state.driverLicenseState }
                                label="Driver's license state*"
                                onChange={ handleChange('driverLicenseState') }
                                sx={ styles.select }
                            >
                                { Object.keys(STATES).map((
                                    state: string,
                                    index: number,
                                ) => (
                                    <MenuItem
                                        value={ STATES[state] }
                                        key={ index }
                                    >
                                        { STATES[state] }
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item mobile={ 12 } tablet={ 6 }>
                        <TextField
                            label="Driver's license number*"
                            value={ state.driverLicenseNumber }
                            sx={{ width: '100%' }}
                            onChange={ handleChange('driverLicenseNumber') }
                            inputProps={{ maxLength: 255 }}
                            error={ !!emptyFieldError
                                && state.driverLicenseNumber === ''
                            }
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Box sx={ styles.buttonsContainer }>
            { isDesktop
                ? <React.Fragment>
                    <Button
                        variant="outlined"
                        sx={ styles.cancelButton }
                        onClick={ onClose }
                        data-testid="btn-cancel"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={ styles.saveButton }
                        onClick={ handleSaveChanges }
                        data-testid="btn-save"
                    >
                        Save Changes
                    </Button>
                </React.Fragment>
                : <React.Fragment>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={ styles.saveButton }
                        onClick={ handleSaveChanges }
                        data-testid="btn-save"
                    >
                        Save Changes
                    </Button>
                    <Button
                        variant="outlined"
                        sx={ styles.cancelButton }
                        onClick={ onClose }
                        data-testid="btn-cancel"
                    >
                        Cancel
                    </Button>
                </React.Fragment>
            }
        </Box>
    </Box>;
};

export default PersonalInfoForm;
