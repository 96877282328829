import { ApiClient, isOffline } from '../../../../common/helpers';
import asyncLogger from '../../../../common/logger';

interface CompleteEntityVerification {
    error?: string;
    entity?: string;
}

export async function completeEntityVerification(
    token: string,
    code?: string,
): Promise<CompleteEntityVerification> {
    try {
        const {
            data: {
                completeEntityVerification,
            },
        } = await ApiClient.request('/completeEntityVerification', {
            body: {
                token,
                code,
            },
            withAuth: false,
        });

        if (!completeEntityVerification) {
            return { error: 'Unable to complete verification process!' };
        }

        return { entity: completeEntityVerification };
    } catch (err: any) {
        if (isOffline(err)) {
            return { error: 'offline' };
        }

        asyncLogger.error(err);
        return { error: err.message };
    }
}
