import React, { FunctionComponent, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { NamedRoute } from '../../../common/hooks/useNamedRoute';
import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import { KnownBreakpoints, THEME_BREAKPOINTS } from '../../../common/constants';
import { ApiClient, isOffline } from '../../../common/helpers';
import { getSendEntityName } from './helpers/converters';
import {
    completeEntityVerification,
} from './helpers/completeEntityVerification';
import asyncLogger from '../../../common/logger';
import OfflineError from '../../OfflineError';
import CodeVerificationForm, { Message } from '../../CodeVerificationForm';

const styles = {
    layout: (theme: Theme, isMFAEditForm: boolean) => ({
        display: 'flex',
        justifyContent: isMFAEditForm ? 'left' : 'center',
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            marginTop: isMFAEditForm ? 'unset' : '-80px',
        },
    }),
};

interface VerificationByCodeFormProps {
    entity: string;
    token: string;
    customerId: string;
    value: string;
    onSuccess: (value: any) => void;
    displayTitle: boolean;
    isMFAEditForm: boolean;
}

const VerificationByCodeForm: FunctionComponent<
    VerificationByCodeFormProps
> = props => {
    const router = useRouter();
    const theme = useTheme();
    const {
        entity,
        token,
        customerId,
        value,
        onSuccess,
        displayTitle,
        isMFAEditForm,
    } = props;

    if (!entity || !token || !customerId || !value) {
        router.push(NamedRoute.HOME).then();
    }

    const [verifyCode, setVerifyCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState<Message | undefined>(undefined);
    const [disabled, setDisabled] = useState(true);
    const [timer, setTimer] = useState(30);
    const [wait, setWait] = useState(false);
    const [networkError, setNetworkError] = useState(false);

    useEffect(() => {
        if (verifyCode !== '' && !verifyCode.includes('_')) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [verifyCode]);

    const handleSendNewCode = async() => {
        if (wait) {
            return ;
        }

        setMessage(undefined);
        setLoading(true);
        setWait(true);

        try {
            const {
                data: { resendEntityVerificationCode },
            } = await ApiClient.request('/resendEntityVerificationCode', {
                body: {
                    customerId,
                    entity: value,
                    entityType: entity,
                    token,
                },
                withAuth: false,
                headers: { 'x-ws-domain': window?.origin } as any,
            });
            const name = getSendEntityName(entity);

            if (!resendEntityVerificationCode) {
                setWait(false);
                setMessage({
                    message: `An error occurred! ${ name } was not sent!`,
                    severity: 'error',
                });
            } else {
                setMessage({
                    message: `New code was sent to ${ value }!`,
                    severity: 'success',
                });
            }
        } catch (err) {
            if (isOffline(err)) {
                setLoading(false);
                setNetworkError(true);
                setWait(false);
                return;
            }

            asyncLogger.error(err);

            setWait(false);
            setMessage({
                message: `${ (err as any).message }`,
                severity: 'error',
            });
        }

        setLoading(false);
    };

    const handleCodeChange = (event: any) => {
        setMessage(undefined);
        setVerifyCode(event.target.value);
    };

    const handleVerifyEntity = async() => {
        if (verifyCode !== '' && verifyCode.includes('_')) {
            return ;
        }

        setMessage(undefined);
        setLoading(true);

        const {
            entity,
            error,
        } = await completeEntityVerification(token, verifyCode);

        if (error === 'offline') {
            setNetworkError(true);
            setLoading(false);
            return;
        }

        if (error) {
            setMessage({
                message: error,
                severity: 'error',
            });
        }

        if (error === 'Your verification session expired!') {
            setMessage({
                message: error + ' Please consider using a new code...',
                severity: 'error',
            });
        }

        if (!entity && !error) {
            setMessage({
                message: 'Verification process was unsuccessful!',
                severity: 'error',
            });
        }

        if (error || !entity) {
            setLoading(false);

            return ;
        }

        onSuccess(entity);
    };

    return <Box sx={ styles.layout(theme, isMFAEditForm) }>
        { networkError &&
            <OfflineError onClose={ () => setNetworkError(false) } />
        }
        <CodeVerificationForm
            entity={ entity }
            value={ value }
            message={ message }
            wait={ wait }
            timer={ timer }
            loading={ loading }
            disabled={ disabled }
            handleCodeChange={ handleCodeChange }
            handleSendNewCode={ handleSendNewCode }
            handleVerifyEntity={ handleVerifyEntity }
            numericCode
            setTimer={ setTimer }
            setWait={ setWait }
            displayTitle={ displayTitle }
        />
    </Box>;
};

export default VerificationByCodeForm;
