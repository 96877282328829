import { formatDateTime, toOrdinalNumber } from './format';
import {
    LAST_DAY_OF_MONTH,
    PAY_FREQUENCIES_MAP,
    WEEK_DAYS_MAP,
} from '../../config/membersArea';
import { sub } from 'date-fns';
import { Employment } from '../types/Employment';
import { IncomeFrequency } from '../types/enums';

export function payFrequencyMap(employment?: Employment) {
    if (!employment) {
        return { 'Pay frequency': '—' };
    }

    const humanizedFrequency = PAY_FREQUENCIES_MAP[employment.frequency];

    switch(employment.frequency) {
        case IncomeFrequency.Weekly:
            return {
                'Pay frequency': humanizedFrequency,
                'Day of the week': WEEK_DAYS_MAP[String(employment.days[0])],
            };
        case IncomeFrequency.BiWeekly: {
            {
                const nextPaymentDate = employment.nextPaymentDate
                    ? new Date(employment.nextPaymentDate)
                    : new Date()
                ;
                const lastPaymentDate = sub(nextPaymentDate, { days: 14 });

                return {
                    'Pay frequency': humanizedFrequency,
                    'Day of the week': WEEK_DAYS_MAP[employment.days[0]],
                    'Last pay date': formatDateTime(
                        String(lastPaymentDate),
                        true,
                    ).split(' ')[0],
                };
            }
        }
        case IncomeFrequency.SemiMonthly:
            return {
                'Pay frequency': humanizedFrequency,
                '1st pay day': toOrdinalNumber(employment.days[0]),
                '2nd pay day': employment.days[1] === Number(LAST_DAY_OF_MONTH)
                    ? 'EOM'
                    : toOrdinalNumber(employment.days[1])
                ,
            };
        case IncomeFrequency.Monthly:
            return {
                'Pay frequency': humanizedFrequency,
                'Day of the month': employment.days[0] ===
                    Number(LAST_DAY_OF_MONTH)
                    ? 'EOM'
                    : toOrdinalNumber(employment.days[0])
                ,
            };
        default:
            return { 'Pay frequency': humanizedFrequency };
    }
}
