import React, { FunctionComponent, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KnownBreakpoints } from '../../../common/constants';
import { isLight } from '../../../common/helpers';
import { DialogMobileLayout } from '../../../layouts/DialogLayout/Mobile';
import PersonalInfoForm from './EditForms/PersonalInfoForm';
import DialogPaperContent from '../../DialogPaperContent';
import SecurityGuaranteeInfo from '../../DialogSide/SecurityGuaranteeInfo';
import { DialogSupportInfoDesktop } from '../../DialogSide/DialogSupportInfo';
import EmploymentInfoForm from './EditForms/EmploymentInfoForm';
import BankInfoForm from './EditForms/BankInfoForm';
import ChangePasswordForm from './EditForms/ChangePasswordForm';
import ChangeSubscriptionsForm from './EditForms/ChangeSubscriptionsForm';
import MFAInfoForm from './EditForms/MFAInfoForm';

const styles = {
    title: (theme: Theme) => ({
        ...theme.typography.h4,
        lineHeight: '28.13px',
        color: theme.palette.text.primary,
        marginBottom: '15px',
    }),
    contentContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    },
    contentText: (withIndent: boolean) => (theme: Theme) => ({
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '30px',
        color: theme.palette.text.primary,
        paddingLeft: withIndent ? '20px' : '0',
    }),
    button: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    divider: (theme: Theme) => ({
        border: `1px solid ${ theme.palette.divider }`,
        marginBottom: '45px',
        marginTop: '20px',
    }),
    mobileContentContainer: (theme: Theme) => ({
        border: `1px solid ${ theme.palette.text.primary }`,
        padding: '27px 20px 18px',
        marginBottom: '20px',
        backgroundColor: isLight(theme)
            ? theme.palette.common.white
            : theme.palette.secondary.main
        ,
    }),
    mobileTitle: (theme: Theme) => ({
        ...theme.typography.h6,
        lineHeight: '18px',
        color: theme.palette.text.primary,
        marginBottom: '15px',
    }),
    mobileTitleDivider: (theme: Theme) => ({
        border: `1px solid ${ theme.palette.text.primary }`,
    }),
    mobileKeyValueContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    mobileKeyValueDivider: (theme: Theme) => ({
        border: `1px solid ${ theme.palette.divider }`,
        margin: '5px 0',
    }),
    mobileButton: {
        marginTop: '18px',
        padding: '0 37px',
    },
    rightDesktop: {
        paddingLeft: '40px',
        paddingRight: '60px',
        marginTop: '110px',
    },
    bottomLaptop: {
        display: 'flex',
    },
};

export interface KeyValueProfileProps {
    content:  { [key: string]: string | JSX.Element };
    title: string;
    editable: boolean;
    id: string;
    marginBottom?: string;
    shadowComponent?: { id: string; offsetY: number };
    reload?: () => void;
    openAlert?: () => void;
    openRequestAlert?: () => void;
}

interface DialogSideContentProps {
    id?: string;
}

export const DialogSideContent: FunctionComponent<
    DialogSideContentProps
> = props => {
    const theme = useTheme<Theme>();
    const isLaptop = useMediaQuery(
        theme.breakpoints.between(
            KnownBreakpoints.tablet,
            KnownBreakpoints.laptop,
        ),
    );

    const { id } = props;

    if (id === 'securityInformation') {
        return <React.Fragment>
            { !isLaptop
                ? <Box sx={ styles.rightDesktop }>
                    <DialogSupportInfoDesktop/>
                </Box>
                : <Box sx={{ display: 'flex', justifyContent: 'center'  }}>
                    <Box>
                        <DialogSupportInfoDesktop/>
                    </Box>
                </Box>
            }
        </React.Fragment>;
    }

    return <React.Fragment>
        { !isLaptop
            ? <Box sx={ styles.rightDesktop }>
                <SecurityGuaranteeInfo/>
                <Box marginTop={ '55px' }>
                    <DialogSupportInfoDesktop/>
                </Box>
            </Box>
            : <Box sx={ styles.bottomLaptop }>
                <Box width={ '50%' }>
                    <SecurityGuaranteeInfo/>
                </Box>
                <Box sx={{ marginLeft: '100px' }} width={ '40%' }>
                    <DialogSupportInfoDesktop/>
                </Box>
            </Box>
        }
    </React.Fragment>;
};

const KeyValueProfileDesktopContent: FunctionComponent<
    KeyValueProfileProps
> = props => {
    const {
        content,
        title,
        editable,
        marginBottom,
        id,
        shadowComponent,
        reload,
        openAlert,
        openRequestAlert,
    } = props;
    const [open, setOpen] = useState(false);
    const [openMFAEditForm, setOpenMFAEditForm] = useState<boolean>(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function editableDesktopContentMap (
        id: string,
        content: { [key: string]: any },
    ): JSX.Element {
        switch (id) {
            case 'personalInformation':
                return <PersonalInfoForm
                    content={ content }
                    key={ id }
                    onClose={ handleClose }
                    onSuccess={ reload }
                    openRequestAlert={ openRequestAlert }
                />;
            case 'employmentInformation':
                return <EmploymentInfoForm
                    content={ content }
                    key={ id }
                    onClose={ handleClose }
                    onSuccess={ reload }
                />;
            case 'bankAccountInformation':
                return <BankInfoForm
                    content={ content }
                    key={ id }
                    onClose={ handleClose }
                    onSuccess={ reload }
                    openRequestAlert={ openRequestAlert }
                />;
            case 'securityInformation':
                return <ChangePasswordForm
                    key={ id }
                    onClose={ handleClose }
                    onSuccess={ reload }
                    openAlert={ openAlert }
                />;
            case 'topicsInformation':
                return <ChangeSubscriptionsForm
                    content={ content }
                    key={ id }
                    onClose={ handleClose }
                    onSuccess={ reload }
                />;
            default:
                return <></>;
        }
    }

    return <Box id={ id }>
        { !!shadowComponent && <Box
            id={ shadowComponent.id }
            position={ 'relative' }
            bottom={ `${ shadowComponent.offsetY }px` }
        /> }
        <Typography component="h4" sx={ styles.title }>
            { title }
        </Typography>
        { Object.keys(content).map((key, index) => (
            <Box key={ index } sx={ styles.contentContainer }>
                <Box sx={{ width: '60%' }}>
                    <Typography
                        sx={
                            styles.contentText((content as any)[key].withIndent)
                        }
                    >
                        { `${key}:` }
                    </Typography>
                </Box>
                <Box sx={{ width: '40%' }}>
                    { getContentValue(
                        (content as any)[key],
                        (value) => <Typography sx={ styles.contentText(false) }>
                            { value }
                        </Typography>,
                    )}
                </Box>
            </Box>
        ))}
        { title === 'Security' &&
            <Box>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={ () => setOpenMFAEditForm(true) }
                    sx={ styles.button }
                    data-testid={ 'btn-mfa-details' }
                >
                    { 'Edit information' }
                </Button>
            </Box>
        }
        { editable &&
            <Button
                variant="outlined"
                size="small"
                onClick={ handleClickOpen }
                sx={ styles.button }
                data-testid={ title !== 'Security'
                    ? 'btn-edit'
                    : 'btn-change-password'
                }
            >
                { title !== 'Security'
                    ? 'Edit information'
                    : 'Change password'
                }
            </Button>
        }
        <Divider sx={ !marginBottom
            ? styles.divider
            : theme => ({
                ...styles.divider(theme),
                marginBottom: marginBottom,
            })
        }/>
        <DialogPaperContent
            dialogDesktopProps={{
                dialogProps: { open: open, onClose: handleClose },
                rightContent: <DialogSideContent id={ id }/>,
                leftContent: editableDesktopContentMap(id, content),
            }}
        />
        <DialogPaperContent
            dialogDesktopProps={{
                dialogProps: {
                    open: openMFAEditForm,
                    onClose: () => {
                        setOpenMFAEditForm(false);
                        reload?.();
                    },
                },
                rightContent: <DialogSideContent id={ id }/>,
                leftContent: <MFAInfoForm
                    content={ content }
                    onClose={ () => {
                        setOpenMFAEditForm(false);
                        reload?.();
                    } }
                    onSuccess={ reload }
                />,
            }}
        />
    </Box>;
};

const KeyValueProfileMobileContent: FunctionComponent<
    KeyValueProfileProps
> = props => {
    const {
        content,
        title,
        editable,
        id,
        reload,
        openAlert,
        openRequestAlert,
    } = props;
    const [open, setOpen] = useState(false);
    const [openMFAEditForm, setOpenMFAEditForm] = useState<boolean>(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function editableMobileContentMap (
        id: string,
        content: { [key: string]: string | JSX.Element },
    ): { title: string, withActions: boolean, children: JSX.Element } {
        switch (id) {
            case 'personalInformation':
                return {
                    title: 'Edit Personal Information',
                    withActions: false,
                    children: <PersonalInfoForm
                        content={ content }
                        onClose={ handleClose }
                        onSuccess={ reload }
                        openRequestAlert={ openRequestAlert }
                    />,
                };
            case 'employmentInformation':
                return {
                    title: 'Edit Employment Information',
                    withActions: false,
                    children: <EmploymentInfoForm
                        content={ content }
                        onClose={ handleClose }
                        onSuccess={ reload }
                    />,
                };
            case 'bankAccountInformation':
                return {
                    title: 'Edit Bank Account Information',
                    withActions: false,
                    children: <BankInfoForm
                        content={ content }
                        onClose={ handleClose }
                        onSuccess={ reload }
                        openRequestAlert={ openRequestAlert }
                    />,
                };
            case 'securityInformation':
                return {
                    title: 'Change Password',
                    withActions: false,
                    children: <ChangePasswordForm
                        onClose={ handleClose }
                        onSuccess={ reload }
                        openAlert={ openAlert }
                    />,
                };
            case 'topicsInformation':
                return {
                    title: 'Message Subscriptions',
                    withActions: false,
                    children: <ChangeSubscriptionsForm
                        content={ content }
                        onClose={ handleClose }
                        onSuccess={ reload }
                        openAlert={ openAlert }
                    />,
                };
            default:
                return {
                    title: '',
                    withActions: false,
                    children: <></>,
                };
        }
    }

    return <Box id={ id } sx={ styles.mobileContentContainer }>
        <Typography component="h6" sx={ styles.mobileTitle }>
            { title }
        </Typography>
        <Divider sx={ styles.mobileTitleDivider }/>
        <Box paddingTop={ '5px' }>
            { Object.keys(content).map((key, index) => (
                <React.Fragment key={ index }>
                    <Box sx={ styles.mobileKeyValueContainer }>
                            <Typography
                                sx={
                                    styles.contentText(
                                        (content as any)[key].withIndent,
                                    )
                                }
                            >
                                { `${key}:` }
                            </Typography>
                            { getContentValue(
                                (content as any)[key],
                                (value) => <Typography
                                    sx={ theme => ({
                                        ...styles.contentText(false)(theme),
                                        textAlign: 'end',
                                        fontWeight: 700,
                                    })}
                                >
                                    { value }
                                </Typography>,
                            )}
                    </Box>
                    { ((index !== Object.keys(content).length - 1) ||
                        (index === Object.keys(content).length - 1 && editable))
                        && <Divider sx={ styles.mobileKeyValueDivider }/>
                    }
                </React.Fragment>
            ))}
        </Box>
        { title === 'Security' &&
            <Box>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={ () => setOpenMFAEditForm(true) }
                    sx={ styles.mobileButton }
                    data-testid={ 'btn-mfa-details' }
                >
                    { 'Edit information' }
                </Button>
            </Box>
        }
        { editable &&
            <Button
                variant="outlined"
                size="small"
                sx={ styles.mobileButton }
                onClick={ handleClickOpen }
                data-testid={ title !== 'Security'
                    ? 'btn-edit'
                    : 'btn-change-password'
                }
            >
                { title !== 'Security'
                    ? 'Edit information'
                    : 'Change password'
                }
            </Button>
        }
        <DialogMobileLayout
            dialogProps={{ open: open, onClose: handleClose }}
            contentProps={ editableMobileContentMap(id, content) }
        />
        <DialogMobileLayout
            dialogProps={{
                open: openMFAEditForm,
                onClose: () => {
                    setOpenMFAEditForm(false);
                    reload?.();
                },
            }}
            contentProps={{
                title: 'Edit Two-Factor Verification Information',
                withActions: false,
                children: <MFAInfoForm
                    content={ content }
                    onClose={ () => {
                        setOpenMFAEditForm(false);
                        reload?.();
                    } }
                    onSuccess={ reload }
                />,
            }}
        />
    </Box>;
};

const KeyValueProfileContent: FunctionComponent<KeyValueProfileProps>
    = props =>
{
    const theme = useTheme<Theme>();
    const isDesktop = useMediaQuery(
        theme.breakpoints.up(KnownBreakpoints.tablet),
    );

    const {
        content,
        title,
        editable,
        marginBottom,
        id,
        shadowComponent,
        reload,
        openAlert,
        openRequestAlert,
    } = props;

    return isDesktop
        ? <KeyValueProfileDesktopContent
            id={ id }
            content={ content }
            title={ title }
            editable={ editable }
            shadowComponent={ shadowComponent }
            marginBottom={ marginBottom }
            reload={ reload }
            openAlert={ openAlert }
            openRequestAlert={ openRequestAlert }
        />
        : <KeyValueProfileMobileContent
            id={ id }
            content={ content }
            title={ title }
            editable={ editable }
            reload={ reload }
            openAlert={ openAlert }
            openRequestAlert={ openRequestAlert }
        />;
};

function getContentValue(
    value: any,
    createStringValue: (value: string) => JSX.Element,
) {
    if (value.displayValue) {
        value = value.displayValue;
    }
    return typeof value === 'string'
        ? createStringValue(value)
        : value;
}

export default KeyValueProfileContent;
