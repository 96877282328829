import { getDay, previousDay, sub } from 'date-fns';

export default function getBiWeeklyDates(day: string): [Date, Date] {
    let dayOfWeek;

    switch (day) {
        case ('Monday'):
            dayOfWeek = 1;
            break;
        case ('Tuesday'):
            dayOfWeek = 2;
            break;
        case ('Wednesday'):
            dayOfWeek = 3;
            break;
        case ('Thursday'):
            dayOfWeek = 4;
            break;
        case ('Friday'):
            dayOfWeek = 5;
            break;
        case ('Saturday'):
            dayOfWeek = 6;
            break;
        default:
            dayOfWeek = 7;
    }

    const currentDate = new Date();
    const currentWeekDay = getDay(currentDate);

    if (currentWeekDay === dayOfWeek) {
        return [
            sub(currentDate, { days: 7 }),
            sub(currentDate, { days: 14 }),
        ];
    }

    const previousDate = previousDay(currentDate, dayOfWeek);

    return [previousDate, sub(previousDate, { days: 7 })];
}
